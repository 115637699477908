//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/login"
export const GET_MERCHANTS_DATA = "/merchants_data"
export const GET_DISABLED_PRODUCTS = "/products/disabled/discount/store"
export const GET_MERCHANTS_DATA_FILTER = "/dashboard/filter-data"
export const PUT_UPDATE_MERCHANTS = "/merchants"
export const PUT_UPDATE_STORE = "/store"
export const GET_COUPON_CODE = "/dashboard/discount_coupon"
export const PUT_COUPON_CODE = "/dashboard/discount_coupon"
export const GET_UPDATE_SCRIPT = "/loadScript"
export const CAMPAIGN_METAFIELDS = "/campaign-metafields"
export const CREATE_CAMPAIGN = "/create-campaign"
export const CAMPAIGN_LIST = "/campaign-list"
export const CUSTOM_BLOCK_METAFIELDS = "/custom-block-metafields"
export const HOME_PAGE_PROPERTY = "/property"
export const GET_PLATFORM = "/platform"
export const PUT_PLATFORM = "/platform"
export const DELETE_PLATFORM = "/platform"
export const POST_TOKEN = "/token"
export const POST_REGISTER = "/register"
export const GET_APP_EMBED = "/app-embed"
export const BLOCKED_COUPON = "/blocked-coupon"

export const PUT_PRODUCTS = "/products"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"


//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
// ============================================== New ==================

//REGISTER
// export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const FACEBOOK_LOGIN = "/login_with_facebook"
export const GOOGLE_LOGIN = "/login_with_google"
export const APPLE_LOGIN = "/login_with_apple"

// Dashboard
export const GET_DASHBOARD = "/dashboard"
export const GET_DASHBOARD_MERCHANTS = "/dashboard/merchants"
export const PUT_STORE_COLLECTIONS = "/collections"

// Customers
export const GET_VEEPER_CUSTOMERS = "/dashboard/customers"
//USER
export const PUT_SETUP_PROFILE = "/setup_profile"
export const PUT_UPDATE_PROFILE = "/update_profile"
export const GET_PREMIUM_INFO = "/premium_info"
export const GET_CLOSE_ACCOUNT = "/close_account"

//TASK
export const POST_CREATE_TASK = "/create_task"
export const GET_SYNC_TASK = "/sync_task"
export const PUT_COMPLETE_TASK = "/complete_task"
export const PUT_DELETE_TASK = "/delete_task"
export const PUT_ABANDON_TASK = "/abandon_task"
export const POST_RECURRING_TASK = "/recurring_task"
export const GET_RECURRING_TASK_HISTORY = "/recurring_task_history"
export const PUT_END_RECURRENCE = "/end_recurrence"
export const GET_SKIP_ONCE = "/skip_once"

//PROJECTS
export const GET_PROJECTS = "/tasks"
export const GET_PROJECT_DETAIL = "/task"
