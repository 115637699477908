import React from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody } from "reactstrap"
import "./datatables.scss"

const CustomerList = ({ data }) => {

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable data={data} searching={true} displayEntries={true} entries={5} entriesOptions={[5, 10, 20, 50, 100]} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CustomerList
