import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
} from "reactstrap"

import './basic.css';


export default class ThemeInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownOpen: false,
        }
        this.toggledropdown = this.toggledropdown.bind(this)
    }

    toggledropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }))
    }
    render() {
        const { data, title, colSize, store_domain, linkUrl } = this.props;
        let theme = data.split("#");
        return (
            <React.Fragment>
                <Col xl={colSize}>
                    <Card>
                        <CardBody>
                            <div className="d-flex flex-wrap d-between-flex" >
                                <h3 className="card-title mb-3 me-2">{title}</h3>
                                {title === 'Placement' && <Link to={{
                                    pathname: "/placement-edit",
                                    data: { store_domain, placement: data },
                                }} >
                                    <i className="fa fa-edit"></i> Edit
                                </Link>}
                            </div>

                            <div className="d-flex flex-wrap">
                                <div>
                                    {linkUrl && <Link to={{ pathname: "/" + linkUrl, data: { store_domain }, }} > <h5 className="mb-3">{data}</h5> </Link>}
                                    {!linkUrl && 
                                        <h6 className="mb-3 ">
                                            <span>{theme?.[0]}  {theme?.[1] ? "; " + theme?.[1] : ''  } </span>
                                        </h6>
                                        }
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}
