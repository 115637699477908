import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { Component } from "react"
import DiscountType from "./DiscountType"
import BlockedCoupon from "./BlockedCoupon"
import MerchantsList from "./MerchantsList";
import ThemeInfo from "../Dashboard/ThemeInfo"
import RosterCSVUpload from "./RosterCSVUpload";
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import BasicCountInfo from "../Dashboard/BasicCountInfo"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Button,
    Spinner,
} from "reactstrap"

class MerchantsDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            app_embed: "disabled",
            store_name: "",
            store_domain: "",
            store_logo: "",
            app_block: "",
            storefront: '3.0',
            subtotal: 0,
            totalCost: 0,
            orders: 0,

            aov_before_veeper: 0,
            aov_after_veeper: 0,
            aov_without_veeper: 0,
            aov_with_veeper: 0,
            aov_change: 0,
            aov_boost: 0,

            avgDiscount: 0,
            profitMargin: 0,
            revenue: 0,
            totalCustomers: 0,
            salesProducts: 0,
            totalProducts: 0,
            is_cart_page_show: false,
            is_product_page_show: false,
            is_home_page_show: false,
            dropdownOpen: false,
            coupon_list: "",
            disabled_products: 0,
            storeStatus: "active",
            subtotal_with_veeper: 0,
            subtotal_without_veeper: 0,
            isLoading: false,
            isCouponLoading: false,
            variable_charge: 0,
            fixed_charge: 0,
            store_details: {},
            hasError: false,

        }
    }

    taggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    componentDidMount() {
        const { data, storeStatus } = this.props.location;
        if (!data) {
            return window.location.href = '/stores';
        }

        const {
            store_name,
            store_domain,
            store_logo,
            app_block,
            storefront,
            email,
            subtotal,
            total,
            total_cost,
            total_discount,
            total_orders,
            discount_type,
            max_discount,
            placement,
            aov_before_veeper,
            total_order_without_veeper,
            subtotal_without_veeper,
            is_cart_page_show,
            is_product_page_show,
            is_home_page_show,
            variable_charge,
            fixed_charge,
        } = data;
        let store_details = { ...data };

        let app_block_status = (storefront == '3.0') ? "Not Access #" : (app_block === 0) ? "Storefront 2.0#" : (app_block === 1) ? "Storefront 1.0 # App block on Cart Page" : (app_block === 2) ? "Storefront 1.0#App block on Product Page" : "Storefront 1.0 # App block on both Cart and Product Page ";

        let aov_after_veeper = (subtotal || subtotal_without_veeper) ? Math.round((Number(subtotal) + Number(subtotal_without_veeper)) / (Number(total_orders) + Number(total_order_without_veeper))) : 0;
        let aov_with_veeper = (subtotal) ? Math.round(subtotal / total_orders) : 0; Number
        let aov_without_veeper = (subtotal_without_veeper) ? Math.round(subtotal_without_veeper / total_order_without_veeper) : 0;
        let aov_change = (aov_before_veeper) ? ((aov_after_veeper - aov_before_veeper) / aov_before_veeper) * 100 : aov_after_veeper * 100;
        let aov_boost = (aov_without_veeper) ? ((aov_with_veeper - aov_without_veeper) / aov_without_veeper) * 100 : aov_with_veeper * 100;
        this.setState({
            store_details: { ...store_details },
            store_name,
            store_domain,
            store_logo,
            app_block: app_block_status,
            subtotal: (subtotal) ? subtotal : 0,
            totalCost: (total_cost) ? total_cost : 0,
            orders: (total_orders) ? total_orders : 0,
            subtotal_with_veeper: (subtotal) ? Number(subtotal) : 0,
            subtotal_without_veeper: (subtotal_without_veeper) ? subtotal_without_veeper : 0,

            aov_before_veeper: (aov_before_veeper) ? aov_before_veeper : 0,
            aov_after_veeper: Number(aov_after_veeper),
            aov_with_veeper: aov_with_veeper,
            aov_without_veeper: aov_without_veeper,
            aov_change: aov_change,
            aov_boost: aov_boost,
            cart_page_show: (is_cart_page_show === false) ? 'shown' : 'hidden',
            product_page_show: (is_product_page_show === false) ? 'shown' : 'hidden',
            home_page_show: (is_home_page_show === false) ? 'shown' : 'hidden',

            avgDiscount: (total_discount) ? (total_discount / total_orders).toFixed(2) : 0,
            profitMargin: (subtotal) ? ((subtotal - total_cost) * 100 / subtotal).toFixed(2) : 0,
            revenue: (total) ? total : 0,
            discount_type: (discount_type) ? discount_type : "",
            max_discount: (max_discount) ? max_discount : "",
            placement: (placement) ? placement : "",
            storeStatus: (storeStatus) ? storeStatus : "active",
            variable_charge: (variable_charge) ? Number(variable_charge) : 0,
            fixed_charge: (fixed_charge) ? Number(fixed_charge) : 0,
            isLoading: true,
            isCouponLoading: true,
        });

        // console.log('sdad',this.state.store_details)
        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        fetch(`${API_URL}${url.GET_MERCHANTS_DATA}?store_name=${store_domain}`, {
            method: 'get',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(res => {
                const { data, status } = res;
                if (!status) {
                    this.setState({
                        isLoading: false
                    })
                    return;
                }
                // const { data: merchants_info, status} = merchants_data;
                const { totalCustomers, salesProducts, totalProducts, app_embed } = data;
                this.setState({
                    totalCustomers,
                    salesProducts,
                    totalProducts,
                    app_embed,
                    isLoading: false
                })
            })
            .catch(err => {
                console.log(err);
            });

        (storeStatus === "active") &&
            fetch(`${API_URL}${url.GET_COUPON_CODE}?shop=${store_domain}`, {
                method: 'get',
                headers: {
                    'Authorization': `${BearerToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(res => {
                    const { data, status } = res;
                    if (!status) {
                        this.setState({
                            isCouponLoading: false
                        })
                        return;
                    }

                    let coupon_list = data.map(coupon => ({
                        id: coupon.id,
                        title: coupon.codeDiscount.title,
                        status: coupon.codeDiscount.status,
                        button: coupon.codeDiscount.status === "ACTIVE" ? <Button color="danger" onClick={() => this.handleClick(coupon.id, 'deactive')}> Disable </Button> : <Button color="success" onClick={() => this.handleClick(coupon.id, 'active')}> Enable </Button>,
                    }));

                    const table_records = {
                        columns: [
                            {
                                label: "ID",
                                field: "id",
                                sort: "asc",
                            },
                            {
                                label: "Coupon Code",
                                field: "title",
                                sort: "asc",
                                width: 150
                            },
                            {
                                label: "Status",
                                field: "status",
                                sort: "asc",
                                width: 270
                            },
                            {
                                label: "Action",
                                field: "button",
                                sort: "asc",
                                width: 100
                            },
                        ],
                        rows: coupon_list
                    }

                    this.setState({
                        coupon_list: table_records,
                        isCouponLoading: false
                    })
                }).catch(err => {
                    console.log(err);
                });
    }

    handleClick = (id, status) => {
        console.log({ id, status });
        const store_domain = this.state.store_domain;
        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        fetch(`${API_URL}${url.PUT_COUPON_CODE}`, {
            method: 'put',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop: store_domain,
                id,
                status
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (!data.status) {
                    return;
                }
                const price_rule_status = (status === 'active') ? 'ACTIVE' : 'EXPIRED';
                console.log({ price_rule_status });

                const coupon_list = this.state.coupon_list.rows.map(coupon => {
                    if (coupon.id === id) {
                        coupon.status = price_rule_status;
                        coupon.button = price_rule_status === "ACTIVE" ? <Button color="danger" onClick={() => this.handleClick(coupon.id, 'deactive')}> Disable </Button> : <Button color="success" onClick={() => this.handleClick(coupon.id, 'active')}> Enable </Button>;
                    }
                    return coupon;
                });

                console.log(coupon_list);
                this.setState({
                    ...this.state,
                    coupon_list: { ...this.state.coupon_list, ...coupon_list }
                })
            })
    }

    changeValue = (value) => {
        const store_domain = this.state.store_domain;
        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        console.log({ store_domain, value })
        fetch(`${API_URL}${url.GET_MERCHANTS_DATA_FILTER}?store_name=${store_domain}&filterType=${value}`, {
            method: 'get',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                console.log(response)
                const {
                    status,
                    data: {
                        ProfitMargin,
                        AOVBeforeVeeper,
                        AOVAfterVeeper,
                        AOVWithVeeper,
                        AOVWithoutVeeper,
                        AOVCHange,
                        AOVBoost,
                        avgDiscountWithVeeper,
                        totalOrderWithVeeper,
                        totalSubtotalWithVeeper,
                        totalSubtotalWithoutVeeper,
                        totalLineItemWithVeeper,
                        totalLineItemWithoutVeeper
                    }
                } = response;
                this.setState({
                    aov_before_veeper: (AOVBeforeVeeper) ? AOVBeforeVeeper : 0,
                    aov_after_veeper: (AOVAfterVeeper) ? AOVAfterVeeper : 0,
                    aov_with_veeper: (AOVWithVeeper) ? AOVWithVeeper : 0,
                    aov_without_veeper: (AOVWithoutVeeper) ? AOVWithoutVeeper : 0,
                    aov_change: (AOVCHange) ? (AOVCHange).toFixed(2) : 0,
                    aov_boost: (AOVBoost) ? AOVBoost : 0,
                    avgDiscount: (avgDiscountWithVeeper) ? avgDiscountWithVeeper : 0,
                    profitMargin: (ProfitMargin) ? ProfitMargin : 0,
                    orders: totalOrderWithVeeper,
                    subtotal: totalSubtotalWithVeeper,
                    subtotal_with_veeper: totalSubtotalWithVeeper,
                    subtotal_without_veeper: totalSubtotalWithoutVeeper,
                    salesProducts: Number(totalLineItemWithVeeper) + Number(totalLineItemWithoutVeeper),
                })
            }).catch(err => {
                console.log(err);
            })
        // this.setState({
        //   placement: value
        // })
    }

    handleActionProduct = (shop, metafield_key, metafield_value) => {
        this.setState({
            isLoading: true
        })
        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        fetch(`${API_URL}${url.PUT_PRODUCTS}`, {
            method: 'put',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop: shop,
                metafield_key: metafield_key,
                metafield_value: metafield_value
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                this.setState({
                    isLoading: false
                })
            })
    }

    handleActionCollections = (shop, metafield_key, metafield_value) => {
        this.setState({
            isLoading: true
        })
        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        fetch(`${API_URL}${url.PUT_STORE_COLLECTIONS}`, {
            method: 'put',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop: shop,
                metafield_key: metafield_key,
                metafield_value: metafield_value
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                this.setState({
                    isLoading: false
                })
            })
    }

    render() {
        const {
            hasError,
            store_details,
            storeStatus,
            store_name,
            store_domain,
            store_logo,
            app_block,
            subtotal,
            orders,

            cart_page_show,
            product_page_show,
            home_page_show,

            aov_before_veeper,
            aov_after_veeper,
            aov_without_veeper,
            aov_with_veeper,
            aov_change,
            aov_boost,

            avgDiscount,
            profitMargin,
            revenue,
            totalCustomers,
            salesProducts,
            totalProducts,
            discount_type,
            max_discount,
            placement,
            coupon_list,
            app_embed,
            subtotal_with_veeper,
            subtotal_without_veeper,
            isLoading,
            isCouponLoading,
            variable_charge,
            fixed_charge,
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Merchant Details</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={("Merchant Details")}
                            breadcrumbItem={`${store_name}`}
                        />
                        <Row style={{ margin: "10px 0" }}>
                            <Col sm="3">
                                <img src={store_logo} width={80} height={80} />
                                {isLoading ? <Spinner color="primary" /> : ' '}
                            </Col>
                            <Col sm="3" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                {storeStatus === 'active' ?
                                    <div>
                                        <Button className="btn btn-danger btn-md" onClick={() => this.handleActionCollections(store_domain, 'veeper_status', 'disabled')}> Disable Collections</Button> {' '}
                                        <Button className="btn btn-success btn-md" onClick={() => this.handleActionCollections(store_domain, 'veeper_status', 'enabled')}>Enable Collections</Button>
                                    </div>
                                    : null}
                            </Col>
                            <Col sm="3" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                {storeStatus === 'active' ?
                                    <div>
                                        <Button className="btn btn-danger btn-md" onClick={() => this.handleActionProduct(store_domain, 'veeper_status', 'disabled')}> Disable Products</Button> {' '}
                                        <Button className="btn btn-success btn-md" onClick={() => this.handleActionProduct(store_domain, 'veeper_status', 'enabled')}>Enable Products</Button>
                                    </div>
                                    : null}
                            </Col>
                            <Col sm={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Dropdown
                                    isOpen={this.state.singlebtn1}
                                    toggle={() =>
                                        this.setState({
                                            singlebtn1: !this.state.singlebtn1,
                                        })
                                    }
                                >
                                    <DropdownToggle className="btn btn-secondary" caret>
                                        Timeline{" "}
                                        <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.changeValue('ALL')} >All</DropdownItem>
                                        <DropdownItem onClick={() => this.changeValue('THIS_MONTH')} >Current Month</DropdownItem>
                                        <DropdownItem onClick={() => this.changeValue('LAST_MONTH')} >Last Month</DropdownItem>
                                        <DropdownItem onClick={() => this.changeValue('THIS_YEAR')} >Current Year</DropdownItem>
                                        <DropdownItem onClick={() => this.changeValue('LAST_YEAR')} >Last Year</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row>
                            <BasicCountInfo store_domain={store_domain} data={totalCustomers} colSize="3" title="Customers" />
                            <BasicCountInfo store_domain={store_domain} data={orders} colSize="3" title="Orders" />
                            <BasicCountInfo store_domain={store_domain} data={"$" + Number.parseFloat(subtotal).toFixed(1)} colSize="3" title="Sales" />
                            <BasicCountInfo store_domain={store_domain} data={totalProducts} colSize="3" title="Products" />
                        </Row>

                        <Row>
                            <BasicCountInfo store_domain={store_domain} data={"$" + Number.parseFloat(aov_before_veeper).toFixed(1)} colSize="3" title="AOV Before Veeper" />
                            <BasicCountInfo store_domain={store_domain} data={"$" + Number.parseFloat(aov_after_veeper).toFixed(1)} colSize="3" title="AOV After Veeper" />
                            <BasicCountInfo store_domain={store_domain} data={Number.parseFloat(aov_change).toFixed(1) + "%"} colSize="3" title="AOV Change" />
                            <BasicCountInfo store_domain={store_domain} data={"$" + Number.parseFloat(aov_without_veeper).toFixed(1)} colSize="3" title="AOV Without Veeper" />
                        </Row>
                        <Row>
                            <BasicCountInfo store_domain={store_domain} data={"$" + Number.parseFloat(aov_with_veeper).toFixed(1)} colSize="3" title="AOV With Veeper" />
                            <BasicCountInfo store_domain={store_domain} data={Number.parseFloat(aov_boost).toFixed(1) + "%"} colSize="3" title="AOV Boost" />
                            <BasicCountInfo store_domain={store_domain} data={salesProducts} colSize="3" title="Items Sold" />
                            <BasicCountInfo store_domain={store_domain} data={Number.parseFloat(avgDiscount).toFixed(1) + "%"} colSize="3" title="Avg. Discount" />
                        </Row>
                        <Row>
                            <BasicCountInfo store_domain={store_domain} data={Number.parseFloat(profitMargin).toFixed(1) + "%"} colSize="3" title="Profit Margin" />
                            <BasicCountInfo store_domain={store_domain} data={revenue} colSize="3" title="Total Revenue" />
                            <DiscountType store_domain={store_domain} is_campaign={false} type={discount_type} store_details={store_details} range={max_discount} store_status={storeStatus} colSize="3" title="Discount Type" />
                            <BasicCountInfo store_domain={store_domain} data={placement} store_details={store_details} store_status={storeStatus} colSize="3" linkUrl="placement-edit" title="Placement" />
                        </Row>
                        <Row>
                            {/* Home page */}
                            <BasicCountInfo store_domain={store_domain} data={home_page_show} colSize="3" title="Main Widget" />
                            {/* Cart page */}
                            <BasicCountInfo store_domain={store_domain} data={cart_page_show} colSize="3" title="Checkout Widget" />
                            {/* Product page */}
                            <BasicCountInfo store_domain={store_domain} data={product_page_show} colSize="3" title="Nudge Widget" />

                            <ThemeInfo store_domain={store_domain} data={app_block} colSize="3" title="Theme" />
                        </Row>
                        <Row>
                            <BasicCountInfo store_domain={store_domain} data={"$" + subtotal_with_veeper.toFixed(2)} colSize="3" title="Subtotal with Veeper" />
                            <BasicCountInfo store_domain={store_domain} data={"$" + subtotal_without_veeper.toFixed(2)} colSize="3" title="Subtotal without Veeper" />
                            <BasicCountInfo store_domain={store_domain} data={Math.round(subtotal_with_veeper / (subtotal_with_veeper + subtotal_without_veeper) * 100) + "%"} colSize="3" title="Subtotal Ratio" />
                            <BasicCountInfo store_domain={store_domain} data={variable_charge} data2={fixed_charge} store_details={store_details} store_status={storeStatus} colSize="3" linkUrl="charge-edit" title="Usage Charge" />
                        </Row>
                        {storeStatus === 'active' &&
                            <Row>
                                <BasicCountInfo store_domain={store_domain} data={app_embed} colSize="3" title="App Embed" />
                                <DiscountType store_domain={store_domain} store_details={store_details} store_status={storeStatus} is_campaign={true} colSize="3" title="Campaign Metafields" />
                                <DiscountType store_domain={store_domain} store_details={store_details} store_status={storeStatus} is_campaign={false} is_selector={true} colSize="3" title="Price Selector" />
                                <RosterCSVUpload store_domain={store_domain} store_details={store_details} store_status={storeStatus} colSize="3" title="Roster CSV Upload" />
                            </Row>
                        }
                        <Row>
                            <BlockedCoupon store_domain={store_domain} store_details={store_details} store_status={storeStatus} is_campaign={false} is_selector={true} colSize="3" title="Blocked Coupon List" />
                        </Row>
                        {storeStatus === 'active' &&
                            <Row>
                                <Col xl={12}>
                                    <h4 className="header-title mt-4">Coupons {isCouponLoading ? <Spinner color="primary" /> : ' '}</h4>
                                    <MerchantsList data={coupon_list} option={[20, 30, 40, 60]} />
                                </Col>
                            </Row>
                        }

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

MerchantsDetails.propTypes = {
    t: PropTypes.any
}
export default MerchantsDetails
