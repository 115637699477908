import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
    Container,
    Row,
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";

import BasicCountInfo from "./BasicCountInfo"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TotalRevenue: 0,
            TotalStores: 0,
            GMV: 0,
            Orders: 0,
            RegisteredShoppers: 0,
            GuestShoppers: 0,
            loading: false
        }

        this.togglemodal.bind(this)
        this.togglesubscribemodal.bind(this)
    }

    componentWillMount() {
        // this.setState({loading: true});
        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        // console.log(BearerToken)
        $("#loadingIcon").text("Loading ...").css('color', 'red');
        fetch(`${API_URL}${url.GET_DASHBOARD}`, {
            method: 'get',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                const {
                    status,
                    revenue,
                    totalStores,
                    registeredShoppers,
                    guestShoppers,
                    gmv,
                    orders,
                } = data;

                this.setState({
                    TotalRevenue: revenue,
                    TotalStores: totalStores,
                    RegisteredShoppers: registeredShoppers,
                    GuestShoppers: guestShoppers,
                    GMV: gmv,
                    Orders: orders,
                    loading: true
                })

            }).catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        setTimeout(() => this.setState({ subscribemodal: false }), 2000);

    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }

    togglesubscribemodal = () => {
        this.setState(prevState => ({
            subscribemodal: !prevState.subscribemodal,
        }))
    }

    render() {
        let current_year = new Date().getFullYear()
        const {
            TotalRevenue,
            TotalStores,
            RegisteredShoppers,
            GuestShoppers,
            GMV,
            Orders,
            loading
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Home</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={this.props.t("Home")}
                            breadcrumbItem={this.props.t("How We're doing")}
                        />
                        <Row>
                            <BasicCountInfo store_domain="" colSize="4" data={'$' + TotalRevenue} title="Revenue" />
                            <BasicCountInfo store_domain="" colSize="4" data={TotalStores} linkUrl="stores" title="Stores" />
                            <BasicCountInfo store_domain="" colSize="4" data={GuestShoppers} title="Guest Shoppers" />
                            <BasicCountInfo store_domain="" colSize="4" data={RegisteredShoppers} linkUrl="shoppers" title="Registered Shoppers" />
                            <BasicCountInfo store_domain="" colSize="4" data={'$' + GMV} title={`GMV ` + current_year} />
                            <BasicCountInfo store_domain="" colSize="4" data="$ 0" title={`GMV ` + (current_year + 1)} />
                            <BasicCountInfo store_domain="" colSize="4" data={Orders} title="Total Orders" />
                        </Row>

                    </Container>
                </div>

            </React.Fragment>
        )

    }
}

Dashboard.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Dashboard)
