import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
	Container,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import * as url from "../../helpers/url_helper"
import {API_URL} from "../../helpers/api_helper";
import $ from "jquery"

class PlacementEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      store_domain: "",
      placement: 60,
	  loading: false,
	  store_details: {},
	  store_status: "",
    }
  }
  updateItem = (e) => {
    // e.preventDefault();
    console.log(e);
    this.setState({
      store_domain: e.store_domain,
      placement: e.placement,
    })
  }
  	componentDidMount(){
		const { data } = this.props.location;
		if (!data) {
			return window.location.href = '/stores';
		}
		const {store_domain, placement, store_details, store_status } = data;
		this.setState({
			store_domain,
			placement,
			store_details: {...store_details},
			store_status
		})
	}
	handleValidSubmit = (e) => {
		e.preventDefault();
		const data = this.state;
		const { store_domain, placement } = data;
		console.log({store_domain, placement})
		const BToken = localStorage.getItem("veeperAuthToken");
		const BearerToken = JSON.parse(BToken);
		$(".loading").text("Loading ...").css('color', 'red');

		fetch(`${API_URL}${url.PUT_UPDATE_MERCHANTS}`, {
			method: 'put',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				store_domain,
				placement,
			})
		})
		.then(res => res.json())
		.then(data => {
			$(".loading").text("");
			this.setState({
				loading: true
			})
		})
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	}


  render() {
	  const {store_domain, placement, loading, store_details, store_status } = this.state;
	  console.log({store_domain, placement })
    return (
      <React.Fragment>
		  <div className="page-content">
			<MetaTags>
				<title>Veeper | AP - Placement Edit</title>
          	</MetaTags>
			<Container fluid>
				{/* Render Breadcrumb */}
				<Breadcrumbs
				title={("Placement Edit")}
				breadcrumbItem={("Edit")}
				/>
				<Col md={12}>
					{loading && <div class="alert alert-success" role="alert">
						Placement has been updated successful!
					</div> }
					<Card>
						<CardBody>
							<CardTitle className="h4"></CardTitle>
							<Link to={{ pathname: "/merchant-details", data: store_details, storeStatus: store_status }} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
								
								<Button className="btn btn-dark">
									<i className="fa fa-arrow-left"></i> Store Details 
								</Button>
							</Link>

							<Form onSubmit={this.handleValidSubmit}>
								<FormGroup className="row mb-4">
								<Label
									htmlFor="horizontal-store_domain-Input"
									className="col-sm-1 col-form-label text-md-right"
									style={{textAlign: "center"}}
								>
									Store Domain
								</Label>
								<Col sm={4}>
									<Input
									value={store_domain}
									type="text"
									name="store_domain"
									className="form-control"
									id="horizontal-store_domain-Input"
									onChange={this.handleInputChange}
									readOnly
									/>
								</Col>
								</FormGroup>
								<FormGroup className="row mb-4">
								<Label
									htmlFor="horizontal-placement-Input"
									className="col-sm-1 col-form-label text-md-right"
									style={{textAlign: "center"}}
								>
									placement
								</Label>
								<Col sm={4}>
									<Input
									value={placement}
									type="number"
									name="placement"
									className="form-control"
									id="horizontal-placement-Input"
									onChange={this.handleInputChange}
									required
									/>
								</Col>
								</FormGroup>

								<FormGroup className="row justify-content-end">
								<Col sm={11}>
									<div>
									<Button
										type="submit"
										color="primary"
										className="w-md"
									>
										Update
									</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
									</div>
								</Col>
								</FormGroup>
							</Form>
						</CardBody>
					</Card>
				</Col>

			</Container>
        </div>
	  </React.Fragment>
    )
  }
}

PlacementEdit.propTypes = {
	t: PropTypes.any
}
export default PlacementEdit
