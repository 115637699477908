import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
	Container,
	FormGroup,
	Label,
	Button,
	Form,
	Input,
	Table,
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import $ from "jquery"

class ChargeEdit extends Component {
	constructor(props) {
		super(props)
		this.state = {
			store_domain: "",
			variable_charge: 2.5,
			fixed_charge: 0,
			loading: false,
			store_details: props.location.data.store_details,
			store_status: "",
		}
	}
	updateItem = (e) => {
		// e.preventDefault();
		console.log(e);
		this.setState({
			store_domain: e.store_domain,
			variable_charge: e.variable_charge,
			fixed_charge: e.fixed_charge,
		})
	}
	componentDidMount() {
		const { data } = this.props.location;
		if (!data) {
			return window.location.href = '/stores';
		}
		const { store_domain, variable_charge, fixed_charge, store_details, store_status } = data;
		this.setState({
			store_domain,
			variable_charge: Number(variable_charge),
			fixed_charge: Number(fixed_charge),
			store_details: { ...store_details },
			store_status
		})
	}
	handleValidSubmit = (e) => {
		e.preventDefault();
		const data = this.state;
		const { store_domain, variable_charge, fixed_charge } = data;
		console.log({ store_domain, variable_charge, fixed_charge })
		const BToken = localStorage.getItem("veeperAuthToken");
		const BearerToken = JSON.parse(BToken);
		$(".loading").text("Loading ...").css('color', 'red');
		console.log(`${API_URL}${url.PUT_UPDATE_STORE}`)

		fetch(`${API_URL}${url.PUT_UPDATE_STORE}`, {
			method: 'put',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				store_domain,
				variable_charge: Number(variable_charge),
				fixed_charge: Number(fixed_charge),
			})
		})
			.then(res => res.json())
			.then(data => {
				$(".loading").text("");
				this.setState({
					loading: true
				})
				// const {
				// 	status,
				// 	entity
				// } = data ;
				// if(status) {

				// 	this.props.history.push("/mafia-config")
				// }
			})
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	render() {
		const { store_domain, store_details, store_status, variable_charge, fixed_charge, loading } = this.state;
		console.log({ store_domain, variable_charge, fixed_charge })
		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>Veeper | AP - Usage Charge Edit</title>
					</MetaTags>
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={("Usage Charge Edit")}
							breadcrumbItem={("Edit")}
						/>
						<Col md={12}>
							{loading && <div class="alert alert-success" role="alert">
								Usage Charge has been updated successful!
							</div>}
							<Card>
								<CardBody>

									<CardTitle className="h4"></CardTitle>
									<Link to={{ pathname: "/merchant-details", data: store_details, storeStatus: store_status }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

										<Button className="btn btn-dark">
											<i className="fa fa-arrow-left"></i> Store Details
										</Button>
									</Link>

									<Form onSubmit={this.handleValidSubmit}>
										<FormGroup className="row mb-4">
											<Label
												htmlFor="horizontal-store_domain-Input"
												className="col-sm-2 col-form-label text-md-right"
												style={{ textAlign: "center" }}
											>
												Store Domain
											</Label>
											<Col sm={4}>
												<Input
													value={store_domain}
													type="text"
													name="store_domain"
													className="form-control"
													id="horizontal-store_domain-Input"
													onChange={this.handleInputChange}
													readOnly
												/>
											</Col>
										</FormGroup>
										<FormGroup className="row mb-4">
											<Label
												htmlFor="horizontal-variable_charge-Input"
												className="col-sm-2 col-form-label text-md-right"
												style={{ textAlign: "center" }}
											>
												Variable Charge(%)
											</Label>
											<Col sm={4}>
												<Input
													value={variable_charge}
													type="number"
													name="variable_charge"
													className="form-control"
													id="horizontal-variable_charge-Input"
													onChange={this.handleInputChange}
													required
												/>
											</Col>
										</FormGroup>

										<FormGroup className="row mb-4">
											<Label
												htmlFor="horizontal-fixed_charge-Input"
												className="col-sm-2 col-form-label text-md-right"
												style={{ textAlign: "center" }}
											>
												Fixed Charge($)
											</Label>
											<Col sm={4}>
												<Input
													value={fixed_charge}
													type="number"
													name="fixed_charge"
													className="form-control"
													id="horizontal-fixed_charge-Input"
													onChange={this.handleInputChange}
													required
												/>
											</Col>
										</FormGroup>

										<FormGroup className="row mb-4 justify-content-end">
											<Col sm={10}>
												<div>
													<Button
														type="submit"
														color="primary"
														className="w-md"
													>
														Update
													</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
												</div>
											</Col>
										</FormGroup>
									</Form>
								</CardBody>
							</Card>
						</Col>

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

ChargeEdit.propTypes = {
	t: PropTypes.any
}
export default ChargeEdit
