import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, } from "reactstrap"

export default class RosterCSVUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownOpen: false,
        }
        this.toggledropdown = this.toggledropdown.bind(this)
    }

    componentDidMount() {
        const data = this.props;
        if (!data) {
            return window.location.href = '/stores';
        }
    }

    toggledropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }))
    }
    render() {
        const { title, type, range, colSize, store_domain, store_details, store_status } = this.props;

        return (
            <React.Fragment>
                <Col xl={colSize}>
                    <Card>
                        <CardBody>
                            <div className="d-flex flex-wrap d-between-flex" >
                                <h3 className="card-title mb-3 me-2">{title}</h3>
                                <Link to={{
                                    pathname: "/upload-roster-csv",
                                    data: { store_domain, store_status, store_details },
                                }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <i className="fa fa-edit"></i> Add Roster
                                </Link>
                            </div>
                            <div className="d-flex flex-wrap">
                                <div>
                                    <h6 className="mb-3 text-primary" ><i>{type} </i></h6>
                                    {type === 'Manual' && <h5 className="mb-0 mb">{range}px</h5>}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}
