import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
} from "reactstrap"

import './basic.css';


export default class BasicCountInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownOpen: false,
        }
        this.toggledropdown = this.toggledropdown.bind(this)
    }

    toggledropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }))
    }
    render() {
        const { data, data2 = '-1', title, colSize, store_domain, linkUrl, store_details, store_status } = this.props;
        return (
            <React.Fragment>
                <Col xl={colSize}>
                    <Card>
                        <CardBody>
                            <div className="d-flex flex-wrap d-between-flex" >
                                <h3 className="card-title mb-3 me-2">{title}</h3>
                                {title === 'Placement' && <Link to={{
                                    pathname: "/placement-edit",
                                    data: { store_domain, placement: data,  store_details, store_status  },
                                }} >
                                    <i className="fa fa-edit"></i> Edit
                                </Link>}

                                {data2 != '-1' && <Link to={{
                                    pathname: "/usage-charges-edit",
                                    data: { store_domain, variable_charge: data, fixed_charge: data2, store_details, store_status },
                                }} >
                                    <i className="fa fa-edit"></i> edit
                                </Link>}
                            </div>

                            <div className="d-flex flex-wrap">
                                <div>
                                    {linkUrl && data2 === '-1' && <Link to={{ pathname: "/" + linkUrl, data: { store_domain }, }} > <h5 className="mb-3">{data}</h5> </Link>}
                                    {linkUrl && data2 !== '-1' && <h5 className="mb-3">{data}% + ${data2}</h5>}
                                    {!linkUrl && <h5 className="mb-3 ">{data}</h5>}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}
