import $ from "jquery"
import Papa from 'papaparse';
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import React, { Component } from "react"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Container, FormGroup, Label, Button, Form, Input, Col, Card, CardBody, CardTitle } from "reactstrap"

import 'react-toastify/dist/ReactToastify.css';

class UploadRosterCSV extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shop: "",
            isShow: false,
            loading: false,
            message: "",
            store_details: {},
            store_status: "",
            campaign: null,
            discount: null,
            campaigns: [],
            keys: [],
            scrollCampaigns: [],

        }
    }
    notify_info = (message) => toast.info(`${message}`);
    notify_error = (message) => toast.error(`${message}`);
    notify_success = (message) => toast.success(`${message}`);
    updateItem = (e) => {
        // e.preventDefault();
        this.setState({
            shop: e.shop,
        })
    }
    componentDidMount() {
        const { data } = this.props.location;
        if (!data) {
            return window.location.href = '/stores';
        }
        const { store_domain, store_details, store_status } = data;
        console.log({ store_domain, store_details, store_status });
        this.setState({
            shop: store_domain,
            store_details: { ...store_details },
            store_status
        })
    }


    handleValidSubmit = (e) => {
        e.preventDefault();
        let { shop, campaigns } = this.state;
        if (!shop || !campaigns) {
            alert('Please upload all the files');
            return;
        }

        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        $(".loading").text("Loading ...").css('color', 'red');

        fetch(`${API_URL}${url.CREATE_CAMPAIGN}`, {
            method: 'POST',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop,
                campaigns
            })
        })
            .then(res => res.json())
            .then(data => {
                $(".loading").text("");
                if (data.status === false) {
                    this.notify(data.message);
                    return;
                }

                this.setState({
                    loading: true,
                    isShow: false,
                    campaigns: [],
                    message: `Campaigns created successfully.`

                })
            }).catch(err => {
                $(".loading").text("");
                this.setState({
                    loading: true,
                    isShow: false,
                    campaigns: [],
                    message: `Error occured while creating campaigns.`
                })
            })
    }

    handleInputChange = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: this.updateResult
        });
    }

    handleDiscountInputChange = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: this.updateDiscountResult
        });
    }

    updateResult = result => {
        this.setState({ campaign: result.data, isShow: false });
    }

    updateDiscountResult = result => {
        this.setState({ discount: result.data, isShow: false });
    }

    handleTableView = () => {
        const { campaign, discount, shop } = this.state;
        if (!campaign || !discount) {
            this.notify_error(`Please upload both files.`);
            return;
        }

        const updatedCampaign = campaign.map((ele, index) => {
            const link = ele['Referral Link']
            let coupons = ele['Shareable Codes']
            let coupon = coupons ? coupons.split(',')[0] : '';

            const campaign_id = link.split('rstr=')?.[1] || link.split('code=')?.[1] || ''
            const data = discount.find(item => item['Name'] === coupon);
            let camp_object = {
                sn: index + 1,
                name: ele['Full Name'],
                link,
                code: campaign_id,
                coupon: coupon,
                discount_type: 'Flat',
                status: 'enabled',
                minimum_purchase: 0,
            }

            if (data) {
                // Convert the discount value to a number if it's a string
                let type = (data['Value Type'] === 'percentage') ? 'Flat' : 'Fixed';
                return {
                    ...camp_object,
                    discount: Math.abs(data['Value']),
                    discount_type: type,
                    minimum_purchase: data['Minimum Purchase Requirements'] || 0,
                    status: data['Status'] === 'Active' ? 'enabled' : 'disabled',
                }
            }

            return {
                ...camp_object,
                discount: 10,
            }
        });
        const keys = Object.keys(updatedCampaign[0]);
        this.setState({ campaigns: updatedCampaign, keys, isShow: true });
    }

    handleCampaigs = () => {
        this.notify_info("Loading campaigns ...");
        const { shop } = this.state;

        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        fetch(`${API_URL}${url.CAMPAIGN_LIST}?shop=${shop}`, {
            method: 'GET',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(res_data => {
                const { status, data } = res_data;
                if (!status) {
                    this.notify("Error occured while loading campaigns.");
                    return;
                }

                let sn = 1;
                let campaigns = [];
                for (let item of data) {
                    campaigns.push({
                        sn: sn++,
                        name: item.name,
                        code: item.code,
                        link: item.link,
                        target: item.target,
                        schedule: item.schedule,
                        discount_type: item.discount_type,
                        recipients: item.recipients,
                        price_rounding: item.price_rounding,
                        minimum_purchase: item.minimum_purchase,
                        status: item.status,
                    });
                }

                this.notify_success("Campaigns loaded successfully.");
                const keys = Object.keys(campaigns[0] || {});
                this.setState({
                    keys,
                    campaigns: campaigns,
                    scrollCampaigns: campaigns.slice(0, 10)
                })
            }).catch(err => {
                $(".loading").text("");
                this.setState({
                    loading: true,
                    isShow: false,
                    campaigns: [],
                    message: `Error occured while creating campaigns.`
                })
            })
    }



    handleScroll = (e) => {
        const { campaigns, scrollCampaigns } = this.state;
        const newCampaigns = campaigns.slice(scrollCampaigns.length, scrollCampaigns.length + 10);
        this.setState({ scrollCampaigns: [...scrollCampaigns, ...newCampaigns] });

    };

    render() {
        const { shop, loading, store_details, campaigns, store_status, keys, isShow, message, scrollCampaigns } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Upload CSV</title>
                    </MetaTags>
                    <Container fluid >
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={("Upload CSV")}
                            breadcrumbItem={("CSV Upload")}
                        />
                        <ToastContainer />
                        <Col md={12}>
                            {loading && <div class="alert alert-success" role="alert">
                                {message}
                            </div>}
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4"></CardTitle>
                                    <Link to={{ pathname: "/merchant-details", data: store_details, storeStatus: store_status }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                                        <Button className="btn btn-dark">
                                            <i className="fa fa-arrow-left"></i> Store Details
                                        </Button>
                                    </Link>

                                    <Form onSubmit={this.handleValidSubmit}>
                                        <FormGroup className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-shop-Input"
                                                className="col-sm-2 col-form-label text-md-right"
                                                style={{ textAlign: "center" }}
                                            >
                                                Store Domain
                                            </Label>
                                            <Col sm={4}>
                                                <Input value={shop} type="text" name="shop" className="form-control" id="horizontal-shop-Input" onChange={this.handleInputChange} readOnly />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-placement-Input"
                                                className="col-sm-2 col-form-label text-md-right"
                                                style={{ textAlign: "center" }}
                                            >
                                                Influencer CSV File <span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col sm={4}>
                                                <input type={"file"} id="csvFileInput" accept={".csv"} onChange={this.handleInputChange} />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-placement-Input"
                                                className="col-sm-2 col-form-label text-md-right"
                                                style={{ textAlign: "center" }}
                                            >
                                                Discount CSV File <span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col sm={4}>
                                                <input type={"file"} id="discountedFileInput" accept={".csv"} onChange={this.handleDiscountInputChange} />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup className="row justify-content-end">
                                            <Col sm={8}>
                                                <div>
                                                    {
                                                        isShow && <Button type="submit" color="primary" className="w-md">
                                                            Add Campaign
                                                        </Button>
                                                    }
                                                    {
                                                        !isShow && <button type="button" className="btn btn-danger waves-effect waves-light" onClick={this.handleTableView}>
                                                            Show Data
                                                        </button>
                                                    }

                                                    &nbsp; &nbsp; &nbsp; <span className="loading"></span>
                                                </div>
                                            </Col>
                                            <Col sm={2} style={{ textAlign: "right" }}>
                                                {/* button primary and right corner */}
                                                <Button type="button" color="secondary" className="w-md btn btn-primary float-right" onClick={this.handleCampaigs}>
                                                    All Campaigns
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={12}>
                            {scrollCampaigns.length > 0 && (
                                <div className="table-responsive" >
                                    <h2> Campaign Records </h2>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {keys.map((item, idx) => (
                                                    <th key={idx}>{item}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {scrollCampaigns.map((item, idx) => (
                                                <tr key={idx}>
                                                    {keys.map((key, idx) => (
                                                        <td>{item[key]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                            )}
                        </Col>

                            {scrollCampaigns.length < campaigns.length && (
                                <div className="loading" style={{
                                    padding: '8px 16px',
                                    width: '200px',
                                    margin: ' 10px auto',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    color: '#000',
                                    border: 'none',
                                    borderRadius: '999px',
                                    textAlign: 'center',
                                    border: '1px solid #ccc',
                                }} onClick={this.handleScroll}>More ...</div>
                            )}

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

UploadRosterCSV.propTypes = {
    t: PropTypes.any
}
export default UploadRosterCSV
