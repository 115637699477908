import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, } from "reactstrap"

export default class BlockedCoupon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
    const { title, colSize, store_details, store_domain, store_status } = this.props;

    return (
      <React.Fragment>
        <Col xl={colSize}>
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap d-between-flex" >
                <h3 className="card-title mb-3 me-2">{title}</h3>
                <Link to={{
                  pathname: "/blocked-coupon",
                  data: { store_domain, store_details, store_status },
                }} >
                  <i className="fa fa-eye"></i> View
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}
